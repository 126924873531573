.wrap {
  margin-top: 0;
  padding-bottom: 24px;
}

.headerImg {
  padding-bottom: 0;
}

.imgLine {
  border: none;
}

.createImageWrap {
  margin-top: 50px;
}

.title {
  margin-top: 30px;
}
