.quiltImg {
  border-radius: 4px;
  box-shadow:
    0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
  overflow: hidden;
  padding: 80px;
  background: black;
}

.quiltText {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 44px;
  color: white;
  text-align: center;
}

@media only screen and (max-width: 840px) {
  .quiltImg {
    padding: 48px;
  }

  .quiltText {
    font-size: 30px;
  }
}

@media only screen and (max-width: 420px) {
  .quiltImg {
    padding: 24px;
  }

  .quiltText {
    font-size: 22px;
  }
}
