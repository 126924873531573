.survey__content {
  padding: 59px 17px;

  &__wrapper {
    padding: 40px;
    background-color: white;
  }
}

.survey__info-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}

.survey__info-block__text {
  line-height: 1.4;
}

@media only screen and (max-width: 840px) {
  .survey__info-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  
  .survey__info-block__text {
    width: 100%;
    margin-bottom: 20px;
  }
}
