@import 'utilities/colors.scss';

.list_item {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;

  .list_dot {
    height: 10px;
    width: 10px;
    background-color: $color_blue;
    border-radius: 50%;
    margin: 0 1rem 0 1rem;
    flex-shrink: 0;
  }

  .http_link {
    font-size: 1.5rem;
    color: $color_blue !important;
  }
}

.tableRowBorderBottom {
  border-bottom: 1px solid #c9c9c9;
}

.text {
  font-size: 24px !important;
  color: #333333 !important;
  word-break: break-word;

  @media only screen and (max-width: 1200px) {
      font-size: 18px !important;
  }
  
  @media only screen and (max-width: 900px) {
      font-size: 14px !important;
  }
}

.imgTable {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
