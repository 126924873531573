.header__info {
  color: #293267;
  font-size: 21px;
  font-weight: 400;
  display: flex;
  justify-content: flex-end;
  padding: 13px 0;

  span {
    &:hover {
      border-bottom: 1px solid #4aa3c5;
    }
  }

  @media only screen and (max-width: 900px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 25px 0 20px;
  }

  @media only screen and (max-width: 600px) {
    justify-content: flex-end;
    flex-direction: row;
    gap: 10px;
    padding: 25px 0 20px;
  }
}
