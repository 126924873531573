.firstStickyColumnCell {
  position: sticky;
  background-color: #e2f4fb;
  z-index: 1000;
  min-width: 100px;
  left: 0;

  @media only screen and (max-width: 700px) {
    position: unset;
  }
}