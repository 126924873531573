.addUser__addIcon {
  border-radius: 50%;
  padding: 6px;
  background: #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  color: #fff;
  cursor: pointer;
  margin: 0 auto;

  &:hover {
    background: rgba(#999999, 0.8);
  } 
}

.resendInviteBtn {
  font-size: 18px;
  border: 1px solid #26a3c5;
  background: white;
  font-weight: 400;
  color: #24476a;
  cursor: pointer;
  border-radius: 4px;
  padding: 12px 40px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 0 auto;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}
