@import 'utilities/colors.scss';

.signUp_section {
  background-color: $color_light_blue;
  padding: 50px 0 100px;

  .create_quilt {
    .steps {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin: 64px 16px 32px;

      .step__item {
        display: flex;
        gap: 16px;

        .step__num {
          font-size: 95px;
          color: #03a0e1;
          font-weight: 700;
          line-height: 1;
        }

        .step__info {
          font-size: 18px;
          padding-top: 6px;
          line-height: 1.3;
          max-width: 350px;

          h5 {
            margin: 0 0 3px;
            font-size: 24px;
          }
        }
      }
    }
  }

  .signUpBox {
    background-color: #fff;
    padding: 32px 48px;

    h2 {
      margin-bottom: 16px;
    }

    .signUpBox__link {
      margin-bottom: 48px;
      text-align: center;
      color: $color_dark_blue;

      a {
        color: $color_blue;
      }
    }

    .error_block {
      margin: 50px 0 0;
      color: $color_red;
      font-size: 18px;

      .link {
        color: $color_blue;
        text-decoration: underline;
      }
    }

    .submit_block {
      display: flex;
      justify-content: space-between;
      margin: 32px 0 0;
      gap: 16px;

      .signUpInput {
        margin-top: 20px;

        .label {
          display: flex;
          align-items: center;
          font-size: 22px;
          font-weight: 500;

          input {
            font-size: 18px;
            margin-right: 16px;
            box-sizing: border-box;
            border: 1px solid #90908e;
            accent-color: #90908e;
            width: 24px;
            height: 24px;
            flex-shrink: 0;
          }
        }
      }
    }
  }

  .reCaptcha {
    height: 100px;
    margin-top: 20px;
    width: fit-content;

    .errorMessage {
      color: $color_red;
      font-size: 16px;
      margin-top: 3px;
      margin-left: 14px;
    }
  }

  .input_withTitle {
    min-height: 150px;
  }

  @media only screen and (max-width: 1640px) {
    .create_quilt {
      h1 {
        padding: 0 16px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .signUpBox {
      padding: 32px;
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 16px 0 50px;

    .create_quilt {
      padding: 32px 16px;

      h1 {
        font-size: 40px;
        text-align: center;
      }

      .steps {
        flex-direction: column;

        .step__item {
          .step__info {
            max-width: 100%;
          }
        }
      }
    }

    .signUpBox {
      padding: 24px;

      .submit_block {
        flex-direction: column;

        .signUpInput {
          margin: 0 0 20px;
        }
      }
    }
    .reCaptcha {
      margin: 0;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 0 0 32px;

    .input_withTitle {
      min-height: 120px;
    }

    .create_quilt {
      padding: 16px 0;

      h1 {
        font-size: 30px;
      }

      .steps {
        margin: 32px 16px;

        .step__item {
          .step__info {
            p {
              line-height: 1.6;
            }
          }
        }
      }
    }

    .signUpBox {
      padding: 16px;

      .error_block {
        margin: 32px 0 0;
      }
    }
  }
}

.privacyList {
  .list_item {
    display: flex;
    align-items: baseline;
    margin-bottom: 1rem;

    .list_dot {
      height: 10px;
      width: 10px;
      background-color: #26a3c5;
      border-radius: 50%;
      margin: 0 1rem 0 3rem;
      flex-shrink: 0;
    }
  }
}

.dialogItem {
  margin-bottom: 20px;
}

.customLink {
  text-decoration: none;
  color: #26a3c5;
}
