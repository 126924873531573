.partners {
  background-color: #fff;
  &-text {
    text-align: center;
  }

  &__items {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #4fa6c6;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__gallery {
    position: relative;
    padding: 50px 0;
    margin: 0 30px;
  }

  &__title {
    text-align: center;
    color: #293267;
    font-size: 28px;
    margin-bottom: 35px;
    text-transform: uppercase;
  }

  & .partner-text {
    text-align: center;
    font-size: 15px;

    &.-black {
      text-align: left;
      font-weight: 700;
      padding-left: 1px;
    }

    &.-main {
      margin-bottom: 40px;
    }

    @media screen and (max-width: 900px) {
      margin: 0 15px;
    }
  }
}

.partners .angle-left {
  position: absolute;
  cursor: pointer;
  left: -30px;
  top: 50%;
  margin-top: -30px;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  -webkit-background-size: auto 16px;
  background-size: auto 16px;
  text-align: center;
  color: white;

  .fa {
    margin-top: 4px;
    margin-right: 4px;
  }
}

.partners .angle-right {
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: 50%;
  margin-top: -30px;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  -webkit-background-size: auto 16px;
  background-size: auto 16px;
  text-align: center;
  color: white;

  .fa {
    margin-top: 4px;
    margin-left: 4px;
  }
}

.partners .slick-track {
  display: flex;
  align-items: center;
}

.partner-item {
  text-align: center;
  margin: 0 20px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  outline: none;

  &__wrapp {
    img {
      margin-top: 15px;
    }
  }
}

.partner-item img {
  max-width: 100%;
}

@media screen and (max-width: 900px) {
  .partner-item {
    margin: 10px;
  }
}

@media screen and (max-width: 640px) {
  .partner-item {
    margin: 10px 15px;
  }
}

.landing-footer {
  background-color: #e2f4fb;

  .contact {
    display: flex;
    padding: 27px 0 55px;
  }

  .footer-col {
    width: 33%;
    padding: 0 25px;

    &:not(:last-child) {
      border-right: 1px solid #4fa6c6;
    }
  }

  .title {
    color: #27326b;
    font-size: 28px;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 19px;
  }

  .subscribe-email {
    display: flex;

    input {
      margin-right: 10px;
      line-height: 45px;
      border: none;
      height: 45px;
      border-radius: 6px;
      padding: 15px 25px;
      width: calc(100% - 120px);

      &::placeholder {
        color: #a9a9a9;
      }
    }
  }

  .featured-btn {
    background-color: #9e1e87;
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    padding: 10px 25px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s all;

    @media screen and (max-width: 900px) {
      font-size: 16px;
    }

    a {
      text-decoration: none;
      color: #fff;
    }

    i {
      margin-left: 6px;
      transform: scale(0.8);
    }
  }

  .colored-btn {
    background-color: #009dbd;
    white-space: nowrap;
    &:hover {
      background-color: #009dbd;
    }
    &.donate {
      width: 100%;
      text-align: center;
    }

    &.signup {
      width: 120px;
    }
  }

  .desc {
    color: #2d3a6b;
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
  }

  .icon-links {
    display: flex;
    color: #fff;

    .link-item {
      background-color: #2596be;
      width: 45px;
      height: 45px;
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
      text-decoration: none;
      color: #fff;

      i {
        position: relative;
        top: 1px;
        transform: scale(1.5);
      }
    }
  }

  @media screen and (max-width: 1440px) {
    //width: calc(100% + 20px);
  }

  @media screen and (max-width: 1000px) {
    .icon-links .link-item {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }

    .featured-btn {
      height: 40px;
      padding: 7px 20px;
    }

    .subscribe-email {
      input {
        line-height: 40px;
        height: 40px;
        padding: 7px 20px;
        width: calc(100% - 100px);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .contact {
      flex-wrap: wrap;
      padding: 0 0 35px;

      .footer-col {
        width: 100%;
        margin-top: 20px;
        padding-top: 20px;
        border-right: 0;
        padding-left: 0;

        &:not(:first-child) {
          border-top: 1px solid #fff;
        }
      }
    }
  }
}

.carousel_item {
  text-align: center;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
