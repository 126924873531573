.welcome_container {
  display: flex;
  justify-content: space-between;
  gap: 64px;
  margin: 16px 0;

  .text_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;

    .welcome_text {
      font-size: 22px;
      line-height: 30px;
      color: #326fb5;
      margin: 0;
    }

    .start_button {
      margin-top: 24px;
      width: fit-content;
      align-self: flex-end;
    }
  }

  .image {
    max-width: 500px;
    height: auto;
    width: auto;
    align-self: flex-start;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    gap: 32px;

    .image {
      max-width: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    .text_wrapper {
      .welcome_text {
        font-size: 18px;
        line-height: 24px;
      }

      .start_button {
        margin-top: 12px;
        width: 100%;
      }
    }
  }
}
