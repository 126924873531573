@import 'utilities/colors.scss';

.search_quilt {
  padding: 50px 48px;
  margin: 0px auto;
  max-width: 1696px;

  .searchBox {
    margin: 32px auto 0;
    background-color: #fff;
    padding: 48px;

    > h4 {
      text-align: left;
      margin-bottom: 24px;
    }
  }

  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;

    > div {
      width: 100%;
    }

    input {
      width: 100%;
    }
  }

  .name_block {
    display: flex;
    gap: 24px;
    width: 100%;
  }

  .date_block {
    display: flex;
    gap: 24px;
    width: 100%;
  }

  .date_box {
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 5px;

    .date_box__title {
      font-size: 24px;
      color: $color_dark_blue;
      font-weight: 700;
      text-transform: uppercase;

      @media only screen and (max-width: 1250px) {
        font-size: 22px;
      }
    }
  }

  .date_wrapper {
    display: flex;
    gap: 24px;
    width: 100%;
  }

  .foundBox {
    width: 100%;
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
    margin-top: 40px;
  }

  .foundItem {
    width: 350px;
    background-color: $color_dark_blue;
    display: flex;
    flex-direction: column;

    .foundEmptyText {
      color: $color_dark_blue;
      text-align: center;
      padding: 150px 0;
      border: 12px solid $color_dark_blue;
      background-color: #fff;
    }

    > div {
      color: #fff;
      padding: 16px 12px;
    }

    img {
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
      padding: 12px;
      object-fit: cover;
    }
  }

  .foundItemDates {
    font-size: 18px;
    margin-bottom: 10px;
    color: #fff;
  }

  .foundLink {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .right {
    display: flex;
    justify-content: flex-end;

    .searchBtn {
      margin: 40px 0 0;

      &:disabled {
        color: $color_grey;

        &:hover {
          background-color: #fff;
          cursor: auto;
        }
      }
    }
  }

  .viewBtn {
    padding: 0 10px;
  }

  .progress_box {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 1696px) {
    padding: 50px 0;

    .search_title {
      padding: 0px 48px;
    }

    .searchBox {
      max-width: 1600px;
    }
  }

  @media (max-width: 1600px) {
    .search_title {
      padding: 0px 48px;
    }

    .foundBox {
      justify-content: space-evenly;
    }
  }

  @media (max-width: 1200px) {
    .date_block {
      flex-direction: column;
    }

    .inputBox {
      flex-direction: column;
    }
  }

  @media (max-width: 900px) {
    padding: 50px 0;

    .search_title {
      padding: 0px 24px;
    }

    .searchBox {
      padding: 40px 24px;
    }

    .name_block {
      flex-direction: column;
      gap: 24px;
    }

    .date_block {
      gap: 24px;
    }

    .date_wrapper {
      flex-direction: column;
    }
  }

  @media (max-width: 600px) {
    padding: 32px 0;

    .searchBtn {
      width: 100%;
    }

    .search_title {
      padding: 0px 16px;
    }

    .searchBox {
      padding: 32px 16px;
    }

    .date_box {
      .date_box__title {
        font-size: 18px;
      }
    }

    .progress_box {
      margin-top: 24px;
    }
  }
}
