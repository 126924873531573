.editorBlock__drag {
  width: 100%;
  height: 600px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #24476a;
  background: white;
  gap: 40px;

  position: relative;

  @media (max-width: 600px) {
    height: 400px;
  }
}

.editorBlock__dragIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;

  color: #24476a;
  font-size: 28px;
  font-weight: 700;

  @media (max-width: 600px) {
    font-size: 24px;
  }
}
