.menu-toggle {
  display: none;
}

.header__menu-items {
  min-width: 280px;
  position: absolute;
  z-index: 1000;
  right: 0;
  padding: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  gap: 16px;
  flex-direction: column;
  align-items: flex-start;
}

.header__menu-item {
  cursor: pointer;
  color: #293267;
}

@media only screen and (max-width: 900px) {
  .menu-toggle {
    display: block;
  }
}
