@import 'utilities/colors.scss';

.wrap {
  background-color: #e2f4fb;
  min-height: 100vh;
  padding-top: 50px;

  @media only screen and (max-width: 1200px) {
    padding-top: 50px;
  }

  @media only screen and (max-width: 600px) {
    padding-top: 32px;
  }
}

.userProfileHeaderTableCell {
  padding: 6px 16px;
}

.userProfileBodyTableCell {
  padding: 24px 16px !important;
}

.userProfileHeaderTableCell,
.userProfileBodyTableCell {
  @media only screen and (max-width: 900px) {
    padding: 8px !important;
  }

  @media only screen and (max-width: 600px) {
    &:first-of-type {
      width: 80px !important;
      padding: 8px 8px 8px 0 !important;
    }

    &:nth-of-type(2) {
      max-width: 150px !important;
    }

    &:nth-of-type(3) {
      max-width: 160px !important;
    }
  }
}

.noUser {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.bold {
  font-weight: 700 !important;
}

.tab_title {
  font-size: 48px;
  line-height: 60px;
  color: $color_dark_blue;
  margin-right: 10px;
  font-weight: 500;
  margin: 0;

  @media only screen and (max-width: 600px) {
    font-size: 36px;
    line-height: 48px;
  }
}
