.customPlaceholderWrapper {
  position: relative;
  display: flex;
}

.redAsteriks {
  position: absolute;
  color: #db4646 !important;
  font-size: 16px !important;
  top: 25px;
  left: 55px;
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  .redAsteriks {
    top: 18px;
    left: 48px;
  }
}
