@import 'utilities/colors.scss';

.actions__right {
  display: flex;
  align-items: flex-end;
  gap: 24px
}

.defaultText {
  display: block;
  font-weight: 400;
  color: $color_dark_blue;
  line-height: 34px;
}

.defaultTitle {
  font-size: 36px;
  color: #26a3c5;
  font-weight: 700;
}

.title {
  font-size: 36px;
  color: $color_dark_blue;
  width: 100%;
  overflow-wrap: anywhere;

  @media only screen and (max-width: 1250px) {
    font-size: 26px;
  }
}

.title span:first-child {
  font-weight: 700;
}

.inviteOther {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.userSettings {
  color: $color_dark_blue;
  font-weight: 700;
}

.addUser {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.addUser__block {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 1000px;
}

.addUser__blockInput {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
}

.addUser__select {
  min-width: 400px;
}

.addUser__blockInput2 {
  display: flex;
  align-items: center;
  gap: 20px;
}

.addUser__blockDisableTop {
  @extend .addUser__block;
}

.addUser__right {
  display: flex;
  gap: 10px;
  align-items: center;
}

.addUser__addIcon {
  border-radius: 50%;
  padding: 6px;
  background: #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  color: #fff;

  cursor: pointer;
}

.addUser__addIcon:hover {
  background: rgba(#999999, 0.8);
}

.addUser__blockDisableTopEmailWrap {
  position: relative;
  width: 100%;
}

.addUser__blockDisableBottom {
  margin-top: 50px;
}

.addUser__blockDisableBottomAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  margin-top: 40px;

  span {
    color: #88bf4b;
    font-weight: 700;
  }

  button {
    margin-left: auto;
  }
}

.addUser__blockDisableClick {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.invitedUsers {
  margin: 90px 0 60px 0;
}

.invitedUsers__blocks {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.invitedUsers__block {
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  gap: 20px;
  align-items: center;
}

.invitedUsers__blockLeft,
.invitedUsers__blockRight {
  display: flex;
  align-items: center;
  gap: 30px;
}

.invitedUsers__blockTitle {
  font-size: 24px;
  font-weight: 700;
  color: $color_dark_blue;
  padding-bottom: 5px;
  text-transform: uppercase;

  @media only screen and (max-width: 1250px) {
    font-size: 22px;
  }
}

.invitedUsers__blockText {
  padding: 10px 0;
  font-size: 24px;
  word-break: break-all;
  text-decoration: underline;
}

.actions {
  padding: 60px 0 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.separator-gap {
  margin-top: 60px;
}

//small invited
.small {
  .addUser__select {
    padding: 2px;
    min-width: 130px;
    font-size: 12px;
    height: 24px;
  }

  .invitedUsers__blockTitle {
    display: none;
  }

  .invitedUsers__blockText {
    font-size: 16px;
  }

  &.invitedUsers__blocks {
    gap: 5px;
  }

  .invitedUsers__block {
    gap: 15px;
  }

  @media only screen and (max-width: 1200px) {
    .invitedUsers__block {
      align-items: start;
    }
  }

  @media only screen and (max-width: 600px) {
    .invitedUsers {
      margin: 50px 0;
    }
    .addUser__select {
      height: 24px !important;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .addUser__block {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
  }

  .addUser__select {
    margin-top: 0;
  }

  .addUser__blockInput2 {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .addUser__blockDisableBottomAction {
    flex-direction: column;
    margin-top: 0;
    align-items: self-start;
  }

  .addUser__blockDisableBottom {
    margin-top: 20px;
  }

  .invitedUsers__block {
    flex-direction: column;
    gap: 16px;
    max-width: 500px;
  }

  .actions {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .actions__right {
    align-self: flex-end;
  }
}

@media only screen and (max-width: 600px) {
  .invitedUsers {
    margin: 50px 0;
  }

  .addUser {
    gap: 24px;
  }

  .addUser__select {
    min-width: auto;
    width: 100%;
    height: 48px !important;
  }

  .addUser__blockDisableBottom {
    margin-top: 0;
  }

  .addUser__blockDisableBottomAction {
    margin-top: 0;
  }

  .invitedUsers__blocks {
    gap: 20px;
  }

  .invitedUsers__block {
    gap: 8px;
  }

  .invitedUsers__blockText {
    font-size: 20px;
    margin-top: 0;
    padding: 0;
  }

  .invitedUsers__blockTitle {
    font-size: 18px;
    padding-bottom: 0;
  }

  .actions {
    padding: 40px 0 0;
  }
}
