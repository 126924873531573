@import 'utilities/colors.scss';

.login_container {
  background-color: $color_light_blue;

  .login_box {
    padding: 100px 0;
  }
}

.input_withTitle {
  min-height: 158px;
}

.help-text {
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .login_container {
    .login_box {
      padding: 50px 0;
    }
  }

  .input_withTitle {
    min-height: 120px;
  }
}
