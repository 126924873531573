.footer {
  background: linear-gradient(
    90deg,
    rgba(31, 91, 136, 1) 34%,
    rgba(58, 187, 193, 1) 62%,
    rgba(121, 193, 49, 1) 100%
  );
  background-size: cover;
  box-shadow: 0 -15px 80px #fff;

  .footer_block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 26px 0;

    .footer_info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #fff;

      .footer_list {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
      }

      a {
        color: #fff;
        text-decoration: none;
      }

      .footer_verticalLine {
        border-right: 1px solid #fff;
      }

      .isDesktop {
        display: flex;
      }

      .isMobile {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1600px) {
    .footer_block {
      padding: 26px 0;
    }
  }

  @media screen and (max-width: 1200px) {
    .footer_block {
      flex-direction: column;
      align-items: center;

      .footer_info {
        padding: 20px 0 0;

        .footer_list {
          justify-content: center;
          padding: 0 0 20px;
        }

        a {
          color: #fff;
          text-decoration: none;
        }

        span {
          text-align: center;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    .footer_block {
      padding: 26px 0;
    }
  }

  @media screen and (max-width: 600px) {
    .footer_block {
      padding: 26px 0;

      .footer_info {
        .footer_copyright {
          margin-top: 20px;
        }

        .footer_list {
          padding: 0 0 12px;
        }

        .isDesktop {
          display: none;
        }

        .isMobile {
          display: flex;
        }
      }
    }
  }
}

@media print {
  .footer {
    display: none;
  }
}
