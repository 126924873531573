.faq__content {
  padding: 59px 17px;

  &__wrapper {
    padding: 40px;
    background-color: white;
  }
}

.faq__info-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}
.faq__info-block__text {
  width: 30%;
  line-height: 1.4;
}
.faq__info-block__video {
  width: 65%;
  height: 370px;
}

@media only screen and (max-width: 840px) {
  .faq__info-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .faq__info-block__text {
    width: 100%;
    margin-bottom: 20px;
  }
  .faq__info-block__video {
    margin: 0px auto;
  }
}

.faq__item {
  background: white;
  padding: 5px 22px;
  margin-bottom: 10px;
  border: 1px solid #dddddc;
}
.faq__item-title {
  color: #339900;
  font-size: 24px;
  margin-bottom: 0;
}
.faq__item-arrow {
  color: #66cc33;
  // font-size: 45px;
}
//   .faq__item-header {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-align: center;
//         -ms-flex-align: center;
//             align-items: center;
//     -webkit-box-pack: justify;
//         -ms-flex-pack: justify;
//             justify-content: space-between;
//     cursor: pointer;
//   }
//   .faq__item-body {
//     display: none;
//     font-size: 18px;
//     padding: 0px 0 15px;
//   }
//   .faq__item.-active .faq__item-body {
//     display: block;
//   }
//   .faq__item.-active .faq__item-header {
//     margin-bottom: 18px;
//   }
