.header {
  background: white;

  @media only screen and (max-width: 600px) {
    padding: 0 0 20px;
  }
}

.header__link {
  cursor: pointer;
  color: #293267;
  border-left: 1px solid #4aa3c5;
  padding: 0 16px;

  @media only screen and (max-width: 900px) {
    border-left: none;
  }
}

.header__content {
  display: flex;
  padding: 14px 0 35px 0;
  justify-content: space-between;

  @media only screen and (max-width: 1250px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 900px) {
    padding: 14px 0 35px;
  }

  @media only screen and (max-width: 600px) {
    padding: 25px 0 2.5px;
  }

  @media print {
    margin-bottom: 50px;
  }
}

.header .logo {
  margin-top: 36px;
  height: 75px;
  width: 550px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1250px) {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    height: 100%;
  }

  @media only screen and (max-width: 600px) {
    margin: 0;
  }
}

.header__wrapper {
  flex-direction: column;
  align-items: flex-end;

  @media only screen and (max-width: 1250px) {
    flex-wrap: wrap;
    margin-top: 0;
  }

  @media only screen and (max-width: 578px) {
   align-items: center;
  }
}

.header__buttons {
  display: flex;
  margin-top: 165px;
  gap: 24px;

  :first-child {
    @media only screen and (max-width: 1400px) {
      width: 205px;
    }

    @media only screen and (max-width: 1250px) {
      width: auto;
    }

    @media only screen and (max-width: 578px) {
      width: 150px;
    }

    @media only screen and (max-width: 500px) {
      width: 112px;
    }

    @media only screen and (max-width: 370px) {
      width: 100px;
    }
  }

  :last-child {
    @media only screen and (max-width: 1400px) {
      width: 188px;
    }
    
    @media only screen and (max-width: 1250px) {
      width: auto;
    }

    @media only screen and (max-width: 578px) {
      width: 135px;
    }

    @media only screen and (max-width: 500px) {
      width: 100px;
    }

    @media only screen and (max-width: 370px) {
      width: 90px;
    }
  }

  @media only screen and (max-width: 1250px) {
    margin-top: 30px;
  }

  @media print {
    display: none;
  }
}

.header__button {
  color: #24476a;
  font-size: 34px;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom-width: 1px;
  cursor: pointer;
  border-bottom: 1px solid white;
  text-align: center;

  &:hover {
    border-bottom: 1px solid #4aa3c5;
  }
  
  @media only screen and (max-width: 1250px) {
    font-size: 24px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 18px;
  }
  
  @media only screen and (max-width: 370px) {
    font-size: 16px;
  }
}

.header__button-delimitiier {
  width: 4px;
  background-color: #4aa3c5;
}

.header__lang {
  display: block;
  cursor: pointer;
  border-radius: 3px;
  transition: 0.3s all;
  padding-right: 16px;

  @media only screen and (max-width: 900px) {
    padding-right: 0;
  }

  @media only screen and (max-width: 600px) {
    align-self: flex-start;
  }
}

.header__wrapper .mail {
  font-size: 14px;
  margin-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 280px;

  @media only screen and (max-width: 600px) {
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
  }

  @media only screen and (max-width: 370px) {
    max-width: 200px;
  }
  
  @media only screen and (max-width: 260px) {
    max-width: 100px;
  }
}

.header__button-auth {
  background: #26a3c5;
  color: white;
}

.logo-and-menu-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

@media only screen and (max-width: 900px) {
  .hidden {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .header .logo {
    max-width: 400px;
  }

  .header__user-lang {
    flex-direction: column;
    gap: 8px;
  }
}

@media print {
  .header__info {
    display: none;
  }
}
