@import 'utilities/colors.scss';

.admin_container {
  margin: 50px 0;

  .searchButton_wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 24px;
  }
}

.adminBtn {
  width: fit-content;

  &:disabled {
    color: $color_grey;

    &:hover {
      background-color: #fff;
      cursor: auto;
    }
  }
}

.textarea_wrapper {
  display: flex;
  gap: 24px;
  margin: 24px 0;

  .textarea_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.searchBlockUsers {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 900px) {
  .textarea_wrapper {
    flex-direction: column;
  }
}
