.block {
  padding-bottom: 170px;

  @media only screen and (max-width: 600px) {
    padding-bottom: 60px;
  }
}

.titleQuilt {
  font-size: 36px;
  color: #24476a;
  margin-bottom: 34px;
  overflow-wrap: anywhere;

  @media only screen and (max-width: 1250px) {
    font-size: 26px;
  }
}

.titleQuilt span:first-child {
  font-weight: 700;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tributesImage {
  width: 750px !important;
}

.settings {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.settings__user {
  text-decoration: underline;
  cursor: pointer;
}

.displayActions {
  padding-top: 22px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 16px;

  @media only screen and (max-width: 600px) {
    justify-content: space-between;
  }
}

.displayActions__buttonText {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.info {
  @extend .title;

  margin-top: 50px;
}

.info img {
  width: 440px;
  object-fit: contain;
}

.actions {
  padding-top: 40px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 24px;

  @media only screen and (max-width: 1400px) {
    flex-direction: column;
    align-items: center;
    width: 100%;

    button {
      height: fit-content;
      width: fit-content;
    }
  }
}

.actions__right {
  display: flex;
  align-items: flex-end;
  gap: 24px;
  
  @media only screen and (max-width: 1400px) {
    align-self: flex-end;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.iframePage {
  width: 440px;
  height: 500px;
  border: 2px solid #ccc;
  overflow: hidden;
  margin: 20px auto;
}

.iframePage iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@media print {
  .block {
    display: none;
  }
}
