.header {
  width: 100%;
  padding-top: 50px;

  .title {
    font-size: 62px;
    font-weight: 700;
    color: #24476a;
  }

  .header__items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 50px;

    .header__inspiration {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .header__inspirationText {
        font-weight: 700;
        font-size: 40px;
        color: #26a3c5;

        @media only screen and (max-width: 1250px) {
          font-size: 26px;
        }
      }

      .header__inspirationButton {
        font-size: 27px;
        border: 1px solid #26a3c5;
        background: white;
        font-weight: 400;
        height: 50px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 48px;
      align-self: flex-start;
    }

    .header__items {
      width: 100%;
      flex-direction: column;
      gap: 40px;
      justify-content: center;
      align-items: center;
      margin: 40px 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .header__items {

      .header__inspiration {
        margin: 24px 0;

        .header__inspirationText {
          font-size: 22px;
          text-align: center;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    padding-top: 30px;

    .title {
      font-size: 30px;
      text-align: left;
    }

    .header__items {
      margin: 24px 0;

      .header__inspiration {
        .header__inspirationButton {
          font-size: 20px;
          height: 40px;
        }
      }
    }
  }
}

@media print {
  .header {
    display: none;
  }
}
