.container {
  width: 100%;
  background: #f7fcff;
  padding: 10px;
  box-shadow: 0 0 10px #7a7a7a;
  margin-bottom: 48px;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 30px;
    border: 4px dashed #d2ebfa;
  }
}

.contentBox {
  display: flex;
  gap: 40px;

  .tributes {
    flex: 4;
  }

  .candles {
    flex: 2;
  }
}

.creationBox {
  display: flex;
  gap: 40px;
  flex: 1;

  .creationTributes {
    flex: 4;
  }

  .creationCandles {
    flex: 2;
  }
}

.candle__Item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;

  img {
    height: 50px;
  }

  p {
    margin-top: 15px;
  }
}

.titleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background-color: #c8ddea;
  border-radius: 8px;

  h2 {
    color: #1a4465;
  }
}

.tributeInputsBox {
  display: flex;
  gap: 20px;
  margin: 40px 0 0 0;
  align-items: flex-end;

  .iconSelectBox {
    width: 300px;

    h3 {
      margin-bottom: 10px;
    }

    .iconSelect {
      background-color: white;
      font-size: 22px;
      box-sizing: border-box;
    }
  }
}

.optionIcon {
  width: 30px;
  height: 30px;
}

.record {
  display: flex;
  gap: 20px;
  margin: 40px 0;

  .imgWrapper {
    width: 100px;

    .icon {
      height: 100px;
      width: 100%;
      object-fit: contain;
      min-width: 100px;
    }
  }

  .textBox {
    p {
      color: #34393d;
    }
  }
}

.delete {
  margin-left: auto;
  width: fit-content;
  height: fit-content;
  padding: 3px;
  background: #999999;
  display: flex;
  color: white;
  cursor: pointer;
}

.delete:hover {
  background: rgba(#999999, 0.8);
}

.iconInput {
  width: 100%;
  margin-top: 40px;

  input {
    background-color: white;
  }

  h3 {
    margin-bottom: 10px;
  }
}

.messageInput {
  margin-top: 40px;
  flex: 4;
  box-sizing: border-box;

  h3 {
    margin-bottom: 0;
  }

  .customTextarea {
    background-color: white;
    margin-top: 10px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    margin-top: 30px;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.candlesShare {
  flex: 2;
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  margin-top: 30px;
  position: relative;
}

.shareTooltip {
  position: absolute;
}

.title {
  font-weight: bold;
  margin-bottom: 24px;
  color: #304e70;
  font-size: 25px;
}

.btn svg {
  margin-left: 0;
}

.loadMore {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 60px 0;
}

.devider {
  margin: 20px 0;
}

.reCaptcha {
  display: flex;
  justify-content: center;
  padding: 5px;
}

@media (max-width: 1400px) {
  .candlesShare,
  .actions {
    button {
      padding: 8px 25px;
    }
  }

  .contentBox {
    .tributes {
      flex: 3;
    }

    .candles {
      flex: 2;
    }
  }

  .creationBox {
    .creationTributes {
      flex: 3;
    }

    .creationCandles {
      flex: 2;
    }
  }
}

@media (max-width: 1000px) {
  .container {
    padding: 10px;
    padding: 0;
    border: 1px solid black;
    box-shadow: none;
    border-radius: 8px;
    background-color: white;

    .wrapper {
      border: none;
      padding: 16px;
    }
  }

  .contentBox {
    flex-direction: column;
    gap: 16px;
  }

  .creationBox {
    flex-direction: column;
  }

  .tributes {
    .title {
      color: #45b1d0;
    }
  }

  .titleBox {
    background: none;
    padding: 0px;

    h2 {
      color: #45b1d0;
      font-size: 25px;
    }
  }

  .messageInput {
    .actions {
      justify-content: space-between;
    }
  }

  .candlesShare {
    justify-content: space-between;
  }

  .iconInput {
    margin-top: 16px;
  }

  .tributeInputsBox {
    margin: 16px 0;

    .iconSelectBox {
      width: 150px;
    }
  }

  .actionArow {
    display: none !important;
  }

  .loadMore {
    margin-bottom: 0;
  }

  .record {
    .imgWrapper {
      width: 60px;

      .icon {
        height: 60px;
        width: 100%;
        min-width: 60px;
      }
    }
  }
}

@media (max-width: 400px) {
  .wrapper {
    word-break: break-word;
  }
}
