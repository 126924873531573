.quilt__container {
  position: relative;
  border: 10px solid #6db9dc;
  width: 63px;
  height: 63px;
}

.quilt__container:hover {
  z-index: 99999;
}
