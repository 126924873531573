.steps {
  display: flex;
  gap: 45px;

  @media only screen and (max-width: 1200px) {
    margin: 0 auto 0 22px;
  }

  @media only screen and (max-width: 600px) {
    margin-right: unset;
    gap: 30px;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 150px;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
      width: 105px;
    }

    @media only screen and (max-width: 600px) {
      width: 90px;
    }

    @media only screen and (max-width: 500px) {
      width: 70px;
    }

    @media only screen and (max-width: 400px) {
      width: 60px;
    }

    .stepCircle {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      border: 1px solid #999999;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      position: relative;
      padding: 4px;

      @media only screen and (max-width: 600px) {
        width: 44px;
        height: 44px;
      }

      .stepCircle__inside {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #999999;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 28px;

        @media only screen and (max-width: 600px) {
          font-size: 16px;
        }
      }

      .stepCircle__inside.active {
        background: #26a3c5;
      }
    }

    .stepCircle:after {
      position: absolute;
      display: block;
      content: '';
      width: 150px;
      height: 1px;
      background: #999999;
      left: 64px;
      top: 50%;
      transform: translateY(-50%);

      @media only screen and (max-width: 768px) {
        width: 90px;
      }

      @media only screen and (max-width: 600px) {
        left: 42px;
      }
    }

    .stepCircle__text {
      text-align: center;

      @media only screen and (max-width: 768px) {
        font-size: 18px;
      }

      @media only screen and (max-width: 600px) {
        font-size: 14px;
      }

      @media only screen and (max-width: 500px) {
        font-size: 12px;
      }
    }
  }

  .step:last-child {
    .stepCircle::after {
      display: none;
    }
  }
}
