.textBlock {
  display: flex;
  flex-direction: column;
  gap: 12px;

  ul {
    list-style: unset;
    padding-left: 20px;
    margin-bottom: unset !important;
  }
  
  li {
    list-style: unset;
  }
}

.textBlock .read {
  font-style: italic;
  text-decoration: underline;
  font-size: 18px;
  color: #26476a;
  cursor: pointer;

  @media print {
    display: none;
  }
}

.textBlock__content {
  font-size: 22px;

  @media (max-width: 900px) {
    font-size: 18px;
  }

  @media (max-width: 600px) {
    font-size: 16px;
  }
}
