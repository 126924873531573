@import 'utilities/colors.scss';

.resourcesBigTitle {
  font-size: 62px;
  font-weight: 700;
  color: #24476a;
  margin: 15px 0;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 30px 0;
}

.list_item {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;

  .list_dot {
    height: 10px;
    width: 10px;
    background-color: $color_blue;
    border-radius: 50%;
    margin: 0 1rem 0 3rem;
    flex-shrink: 0;
  }

  .no-indent-dot {
    margin-left: 0;
  }
}

.http_link {
  text-decoration: none;
  color: #26a3c5;
}

.image_block {
  display: flex;
  gap: 36px;
  margin: 30px 0;

  img {
    width: 100%;
    max-width: 600px;
    align-self: start;
  }

  .text_block {
    max-width: 600px;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.resources p {
  display: block;
  margin-top: 15px !important;
  line-height: 130%;
  text-align: justify;
}

.extra_info {
  font-weight: bold;
  margin-bottom: 15px;
}

@media only screen and (max-width: 900px) {
  .image_block {
    grid-template-columns: 1fr;
  }
}
