.renderer__wrapper {
  & ul {
    li {
      list-style: circle !important;
    }
  }

  & ol {
    li {
      list-style: decimal !important;
    }
  }
  blockquote {
    padding: 0 50px;
    margin-left: 0;
    border-left: 2px solid rgb(212, 210, 210);
  }
}
