.button {
  font-size: 18px !important;
  font-weight: 500 !important;
  padding: 10px;
  color: #67798f;
}

.noCapitalize {
  text-transform: none;
  font-size: 60px;
  text-transform: unset !important;
}

.title {
  font-size: 27px;
  text-align: center;
}

.deleteDialogText {
  color: rgb(51, 51, 51);
  font-size: 22px;
  text-align: center;
}

.centeredText {
  text-align: center;
}

.text {
  font-size: 21px;
  line-height: 30px;
  color: #333333;
  text-align: center;
}
