.icon-links {
  display: flex;
  color: #fff;
  padding-left: 14px;

  .link-item {
    background-color: #2596be;
    width: 25px;
    height: 25px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;

    i {
      position: relative;
      top: 1px;
      transform: scale(1);
    }
  }
}

.link-item:last-child {
  margin-right: 0;
}
