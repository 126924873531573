.container {
  position: relative;
  display: flex;
  align-items: center;

  &__wrap {
    position: relative;
  }
}

.slider__container {
  position: relative;
  height: 1008px;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  z-index: 1;
  min-width: 100vw;

  background-image: url('../../../public/img/general/background-home-2.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;

  @media only screen and (max-width: 600px) {
    height: 900px;
    max-width: 450px !important;
    // min-width: unset;
    background-image: url("../../../public/img/general/background-home-phone-2.PNG");
    background-size: 450px 900px;
  }
}

.slider__content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 1008px;
  padding-top: 84px;
  overflow: hidden;

  @media only screen and (max-width: 600px) {
    height: 900px;
    max-width: 450px;
    padding: 111px 37.5px 37.5px;
  }
}

.slider__container_move {
  @extend .slider__container;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.slider {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  min-width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: white;
  background: rgba(0, 0, 0, 0.5);
}

.slider__wrap,
.slider__content {
  width: 1512px;

  @media only screen and (max-width: 2000px) {
    width: 1512px;
  }
  
  @media only screen and (max-width: 1650px) {
    width: 1177px;
  }

  // @media only screen and (max-width: 1240px) {

  // }

  @media only screen and (max-width: 600px) {
    margin: 0 auto;
    width: 490px;
  }
}

.slide__button_prev, 
.slide__button_next {
  display: flex;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);

  @media only screen and (max-width: 600px) {
    top: 45.75%;
    transform: scale(0.7);
  }
}

.slide__button_prev {
  left: 30px;

  @media only screen and (max-width: 600px) {
    left: 60px;
  }

  @media only screen and (max-width: 540px) {
    left: 30px;
  }

  @media only screen and (max-width: 440px) {
    left: 10px;
  }
}

.slide__button_next {
  right: 30px;

  @media only screen and (max-width: 600px) {
    right: 60px;
  }

  @media only screen and (max-width: 540px) {
    right: 30px;
  }

  @media only screen and (max-width: 440px) {
    right: 10px;
  }
}

.carousel__item {
  display: flex;
  width: max-content;
}

.carousel__item img[src] {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel__wrap {
  position: relative;
  top: 106px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.carousel__content_item, 
.carousel__content_item1 {
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  margin: 49px;

  @media only screen and (max-width: 600px) {
    transform: scale(0.6);
    min-width: 55px;
    min-height: 55px;
    max-width: 55px;
    max-height: 55px;
    margin: 10px;
  }
}

.carousel__content_item1 {
  position: relative;
}

.carousel__content_item1Border {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: rotate(135deg);
}

.carousel__content_item1Img {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  transform: scale(1.25);
  border: none !important;
}
