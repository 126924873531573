@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap');

// Common styles of the project

body,
html {
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 22px;
  font-family: 'Open Sans', sans-serif;
  background: #e2f4fb;
  color: black;
  position: relative;

  @media only screen and (max-width: 840px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 420px) {
    font-size: 18px;
  }
}

.center {
  max-width: 1696px;
  padding: 0 48px;
  margin: 0px auto;

  @media screen and (max-width: 1200px) {
    padding: 0 32px;
  }

  @media screen and (max-width: 900px) {
    padding: 0 24px;
  }

  @media screen and (max-width: 600px) {
    padding: 0 16px;
  }
}

.react-multi-carousel-list {
  height: 100%;
  width: 100%;
}

.react-multi-carousel-track {
  gap: 100px;
}

* html .page__wrapper {
  height: 100%;
}

.line-top {
  padding: 40px 0;
  border-top: 1px solid #00a6c9;
  margin-top: 60px;
}

.button {
  padding: 5px 15px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: bottom;
  box-sizing: border-box;
  letter-spacing: 1.3px;
  font-size: 16px;
  border-radius: 3px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  transition: 0.3s all;
  cursor: pointer;
  border: none;

  &:hover {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2) inset;
  }

  &.-green {
    background-color: #66cc33;
    color: black;
  }

  &.-black {
    background-color: black;
    font-size: 20px;
    color: #66cc33;

    @media only screen and (max-width: 840px) {
      font-size: 15px;
    }
  }

  svg {
    margin-left: 15px;
  }

  @media only screen and (max-width: 840px) {
    font-size: 14px;
  }
}

.page {
  &.-bg {
    background: url('../public/img/general/bg-2.jpg') repeat-y;
    background-size: contain;
  }
}

.scroll {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: 30px auto;
  cursor: pointer;
  box-sizing: border-box;
  padding-top: 24px;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  background-color: black;

  i {
    font-size: 50px;
    margin-top: -10px;
  }
}

.image-content {
  max-width: 90%;
}

.frame-block {
  padding: 10px 8px 10px 8px;
  max-width: 450px;
  overflow: hidden;
  background-color: #3b3b3b;
}

.frame-item {
  max-width: 405px;
  padding: 10px;
  border: 2px dashed #bfbfbf;
}

.textarea {
  padding: 10px;
  resize: none;
  font-size: 18px;
  border: 1px solid #90908e;
}

.input {
  padding: 7px;
  font-size: 18px;
  height: 39px;
  box-sizing: border-box;
  border: 1px solid #90908e;

  @media only screen and (max-width: 1200px) {
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 560px) {
    width: 100%;
  }
}

.select {
  padding: 9px 7px;
  border: 1px solid #90908e;
  border-radius: 10px;
  font-size: 17px;
}

label {
  font-size: 18px;
  cursor: pointer;
}

.form {
  h3 {
    font-size: 27px;
    font-weight: 400;

    @media only screen and (max-width: 840px) {
      font-size: 25px;
    }

    @media only screen and (max-width: 420px) {
      font-size: 20px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__footer-buttons {
    display: flex;

    & .button {
      margin-right: 16px;
    }
  }

  &__footer-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 18px;

    & .form__radio-item {
      margin-right: 15px;
    }
  }

  &__items {
    display: flex;
    margin-bottom: 40px;

    @media only screen and (max-width: 840px) {
      flex-wrap: wrap;
      margin-bottom: 8px;
    }
  }

  & .-border {
    padding-bottom: 33px;
    margin-bottom: 33px;
    border-bottom: 1px solid #e0e0e0;
  }

  &__item {
    width: 100%;
    margin-left: 25px;
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    > div {
      width: 100%;
    }

    &:first-child {
      margin-left: 0;
    }

    input {
      font-size: 18px;
    }

    small {
      font-size: 14px;
    }

    @media only screen and (max-width: 840px) {
      margin: 10px;

      &:first-child {
        margin: 10px;
      }
    }
  }

  &__radio-item {
    position: relative;
    padding-left: 20px;
  }

  &__item-radio {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    input {
      display: none;
    }
  }

  & .button.-black {
    font-size: 16px;
    padding: 10px 15px;
  }
}

.form-block {
  &__item {
    margin-bottom: 15px;
  }
  &__label {
    display: flex;
    align-items: center;
  }
  &__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 25px;
  }
  &__form-title {
    margin-bottom: 16px;
    font-size: 22px;
    display: inline-block;
  }
  &__form-item {
    width: 35.6%;
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
    @media only screen and (max-width: 840px) {
      width: 100%;
      margin-left: 0px;
    }
  }
  &__form-select {
    width: 24.6%;
  }
  & .select,
  & .input {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  & .input {
    padding: 9px 7px;
  }
  & .h4 {
    font-size: 22px;
    margin-bottom: 33px;
  }
}

.language {
  display: block;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 3px;
  padding: 5px 15px;
  text-transform: uppercase;
  border: 1px solid #66cc33;
  transition: 0.3s all;
}

.stlradio {
  position: absolute;
  left: 0px;
  top: 7px;
  border: 2px solid #b2b1b1;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 4px;
  border-radius: 50%;
  background-color: #ffffff;
}

.stlradio:before {
  content: '';
  background: #1c1e25;
  width: 8px;
  height: 8px;
  opacity: 0;
  transition: 0.3s;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 300px;
}

input:checked + .stlradio:before {
  opacity: 1;
}

.input__wrapper {
  position: relative;
  margin: 15px 0 10px;
}

.input__file {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.input__file-icon-wrapper {
  height: 60px;
  width: 60px;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-right: 1px solid #fff;
}

.input__file-button-text {
  line-height: 1;
  margin-top: 1px;
}

.input__file-button {
  background: black;
  padding: 9px 15px;
  letter-spacing: 1.3px;
  font-size: 16px;
  font-weight: 500;
  color: #66cc33;
  text-transform: uppercase;
  border-radius: 3px;
  cursor: pointer;
}

.form-editor {
  &__title {
    color: #339900;
    font-size: 27px;
    cursor: pointer;
    margin-bottom: 40px;
  }
}

.input-block {
  margin: 0 10px;
  width: 45%;

  .input {
    width: 100%;
  }

  &__wrapper {
    max-width: 730px;
    margin: 0px auto;
  }

  h3 {
    margin: 0 0 16px;
    font-size: 22px;
    font-weight: 500;
  }
}

.input-blocks {
  display: flex;
  justify-content: center;
}

.checkbox-item {
  padding: 0 26px;
  margin: 20px 0;

  input {
    width: 23px;
    height: 23px;
    vertical-align: middle;
  }

  &__text {
    margin-left: 10px;
  }
}

.popup {
  position: relative;
  padding: 53px 75px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 10px #7a7a7a;
  box-shadow: 0 0 10px #7a7a7a;
  font-size: 22px;
  background-color: white;
}
.popup__header {
  text-align: center;
  margin-bottom: 50px;
}
.popup__header .h2 {
  font-size: 42px;
}
@media only screen and (max-width: 840px) {
  .popup__header .h2 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 420px) {
  .popup__header .h2 {
    font-size: 22px;
  }
}
.popup__header .h3 {
  font-size: 26px;
}
.popup.-user .h2 {
  font-size: 42px;
  font-weight: 500;
  margin-bottom: 15px;
}
@media only screen and (max-width: 840px) {
  .popup.-user .h2 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 420px) {
  .popup.-user .h2 {
    font-size: 22px;
  }
}
.popup.-user p {
  margin-bottom: 20px;
}
.popup.-user .popup__header {
  margin-bottom: 0px;
}
.popup__close {
  cursor: pointer;
  right: 30px;
  top: 16px;
  font-size: 30px;
  position: absolute;
}
.popup__text {
  margin-bottom: 80px;
}

@media print {
  .hiddenInPrint {
    display: none;
  }

  .textForPrint {
    height: auto;
  }

  .imageForPrint {
    margin: 0 auto;
  }

  a {
    text-decoration: underline;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
  }

  a[href='#'],
  a[href='javascript:'] {
    content: '';
  }
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: black;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

p a {
  color: #339900;
  text-decoration: underline;
  transition: 0.5s;
}

.link {
  color: black;
}

a:active {
  text-decoration: none;
  list-style-type: none;
}

a:hover {
  text-decoration: none !important;
  list-style-type: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

img {
  max-width: 100%;
  height: auto;
  border: none;
}

ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px !important;
}

li {
  list-style: none;
}

input:focus {
  outline: none !important;
}

.h1 {
  margin-top: 0;
  font-size: 42px;
  font-weight: 600;
  margin-bottom: 23px;

  @media only screen and (max-width: 840px) {
    font-size: 32px;
  }

  @media only screen and (max-width: 420px) {
    font-size: 22px;
  }
}

.h2,
.h3,
.h4 {
  margin-top: 0;
}

h2 {
  font-size: 25px;

  @media only screen and (max-width: 840px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 420px) {
    font-size: 18px;
  }
}

h3 {
  font-size: 20px;

  @media only screen and (max-width: 420px) {
    font-size: 16px;
  }
}

h2,
h3,
p {
  margin: 0;
  margin-bottom: 5px;
}

sub,
sup {
  position: relative;
  font-size: 70%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.3em;
}

.d-inline {
  display: inline-block;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: flex;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  -webkit-box-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  -webkit-box-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  -webkit-box-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  -webkit-box-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.lh40 {
  line-height: 40px;
}

.text-xs {
  font-size: 10px;
}

.text-sm {
  font-size: 12px;
}

.text-sm-14 {
  font-size: 14px;
}

.text-base {
  font-size: 17px;
}

.text-lg {
  font-size: 20px;
}

.text-xl {
  font-size: 24px;
}

.text-xl2 {
  font-size: 28px;
}

.text-2xl {
  font-size: 30px !important;
}

.text-3xl {
  font-size: 36px !important;
}

.font-italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 900;
}

.regular {
  font-weight: 400;
}

.ligth {
  font-weight: 300;
}

.no-wrap {
  white-space: nowrap;
}

.no-style {
  list-style: none !important;
  padding-left: 0;
}

.my-00 {
  margin: 0px auto;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-150 {
  margin-top: 150px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-0 {
  margin-right: 0;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.py-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.py-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.pl-0 {
  padding-left: 0px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-0 {
  padding-right: 0px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pt-0 {
  padding-top: 0;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.padding-right-15 {
  padding-right: 15px;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline !important;
}

.no-underline {
  text-decoration: none !important;
}

.img-w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

@media print {
  .center {
    padding: 0;
  }
}

.w-1-5,
.w-1-4,
.w-1-3,
.w-2-5,
.w-1-2,
.w-3-5,
.w-3-5,
.w-2-3,
.w-3-4,
.w-4-5,
.w-5-6,
.sm-w-1-5,
.sm-w-1-4,
.sm-w-1-3,
.sm-w-2-5,
.sm-w-1-2,
.sm-w-3-5,
.sm-w-3-5,
.sm-w-2-3,
.sm-w-3-4,
.sm-w-4-5,
.sm-w-5-6,
.lg-w-1-5,
.lg-w-1-4,
.lg-w-1-3,
.lg-w-2-5,
.lg-w-1-2,
.lg-w-3-5,
.lg-w-3-5,
.lg-w-2-3,
.lg-w-3-4,
.lg-w-4-5,
.lg-w-5-6,
.md-w-1-5,
.md-w-1-4,
.md-w-1-3,
.md-w-2-5,
.md-w-1-2,
.md-w-3-5,
.md-w-3-5,
.lgmd-w-2-3,
.md-w-3-4,
.md-w-4-5,
.md-w-5-6,
.w-full {
  padding-left: 15px;
  padding-right: 15px;
}

.w-1-6 {
  width: 16.6666%;
}

.w-1-5 {
  width: 20%;
}

.w-1-4 {
  width: 25%;
}

.w-1-3 {
  width: 33.33333%;
}

.w-2-5 {
  width: 40%;
}

.w-1-2 {
  width: 50%;
}

.w-3-5 {
  width: 60%;
}

.w-2-3 {
  width: 66.666%;
}

.w-3-4 {
  width: 75%;
}

.w-4-5 {
  width: 80%;
}

.w-5-6 {
  width: 83.333333%;
}

.w-full {
  width: 100%;
}

@media (min-width: 565px) {
  .sm-w-1-6 {
    width: 16.6666%;
  }

  .sm-w-1-5 {
    width: 20%;
  }

  .sm-w-1-4 {
    width: 25%;
  }

  .sm-w-1-3 {
    width: 33.33333%;
  }

  .sm-w-3-8 {
    width: 36.5%;
    padding: 0 15px;
  }

  .sm-w-2-5 {
    width: 40%;
  }

  .sm-w-1-2 {
    width: 50%;
  }

  .sm-w-3-5 {
    width: 60%;
  }

  .sm-w-5-8 {
    width: 62.5%;
    padding: 0 15px;
  }

  .sm-w-2-3 {
    width: 66.666%;
  }

  .sm-w-3-4 {
    width: 75%;
  }

  .sm-w-4-5 {
    width: 80%;
  }

  .sm-w-5-6 {
    width: 83.333333%;
  }

  .sm-my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .sm-my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .sm-my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .sm-my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .sm-my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .sm-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .sm-mt-0 {
    margin-top: 0 !important;
  }

  .sm-mt-10 {
    margin-top: 10px;
  }

  .sm-mt-15 {
    margin-top: 15px;
  }

  .sm-mt-30 {
    margin-top: 30px;
  }

  .sm-mt-40 {
    margin-top: 40px;
  }

  .sm-mt-50 {
    margin-top: 50px;
  }

  .sm-mt-70 {
    margin-top: 70px;
  }

  .sm-mb-0 {
    margin-bottom: 0 !important;
  }

  .sm-mb-10 {
    margin-bottom: 10px !important;
  }

  .sm-mb-15 {
    margin-bottom: 15px;
  }

  .sm-mb-20 {
    margin-bottom: 20px !important;
  }

  .sm-mb-30 {
    margin-bottom: 30px;
  }

  .sm-mb-40 {
    margin-bottom: 40px;
  }

  .sm-mb-50 {
    margin-bottom: 50px;
  }

  .sm-ml-0 {
    margin-left: 0;
  }

  .sm-ml-10 {
    margin-left: 10px;
  }

  .sm-ml-15 {
    margin-left: 15px;
  }

  .sm-ml-20 {
    margin-left: 20px;
  }

  .sm-ml-30 {
    margin-left: 30px;
  }

  .sm-mr-0 {
    margin-right: 0;
  }

  .sm-mr-10 {
    margin-right: 10px;
  }

  .sm-mr-15 {
    margin-right: 15px;
  }

  .sm-mr-20 {
    margin-right: 20px;
  }

  .sm-mr-30 {
    margin-right: 30px;
  }

  .sm-p-30 {
    padding: 30px;
  }

  .sm-py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .sm-py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .sm-py-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .sm-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sm-px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .sm-px-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .sm-px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .sm-px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .sm-px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .sm-pl-0 {
    padding-left: 0px;
  }

  .sm-pl-10 {
    padding-left: 10px;
  }

  .sm-pl-15 {
    padding-left: 15px;
  }

  .sm-pl-20 {
    padding-left: 20px;
  }

  .sm-pl-30 {
    padding-left: 30px;
  }

  .sm-pr-0 {
    padding-right: 0px;
  }

  .sm-pr-10 {
    padding-right: 10px;
  }

  .sm-pr-15 {
    padding-right: 15px;
  }

  .sm-pr-20 {
    padding-right: 20px;
  }

  .sm-pr-30 {
    padding-right: 30px;
  }

  .sm-pt-0 {
    padding-top: 0;
  }

  .sm-pt-10 {
    padding-top: 10px;
  }

  .sm-pt-20 {
    padding-top: 20px;
  }

  .sm-pt-30 {
    padding-top: 30px;
  }

  .sm-pt-40 {
    padding-top: 50px;
  }

  .sm-pt-50 {
    padding-top: 50px;
  }

  .sm-pb-0 {
    padding-bottom: 0;
  }

  .sm-pb-10 {
    padding-bottom: 10px;
  }

  .sm-pb-20 {
    padding-bottom: 20px;
  }

  .sm-pb-30 {
    padding-bottom: 30px;
  }

  .sm-pb-40 {
    padding-bottom: 50px;
  }

  .sm-pb-50 {
    padding-bottom: 50px;
  }

  .sm-text-xs {
    font-size: 10px !important;
  }

  .sm-text-sm {
    font-size: 12px !important;
  }

  .sm-text-base {
    font-size: 16px !important;
  }

  .sm-text-lg {
    font-size: 20px !important;
  }

  .sm-text-xl {
    font-size: 24px !important;
  }

  .sm-text-xl2 {
    font-size: 28px !important;
  }

  .sm-text-2xl {
    font-size: 30px !important;
  }
}

@media (min-width: 768px) {
  .md-min-w-140 {
    min-width: 140px;
  }

  .md-min-w-100 {
    min-width: 100px;
  }

  .md-min-w-80 {
    min-width: 80px;
  }

  .md-min-w-60 {
    min-width: 60px;
  }

  .md-w-1-6 {
    width: 16.6666%;
  }

  .md-w-1-5 {
    width: 20%;
  }

  .md-w-1-4 {
    width: 25%;
  }

  .md-w-1-3 {
    width: 33.33333%;
  }

  .md-w-3-8 {
    width: 36.5%;
    padding: 0 15px;
  }

  .md-w-2-5 {
    width: 40%;
  }

  .md-w-1-2 {
    width: 50%;
  }

  .md-w-3-5 {
    width: 60%;
  }

  .md-w-5-8 {
    width: 62.5%;
    padding: 0 15px;
  }

  .md-w-2-3 {
    width: 66.666%;
  }

  .md-w-3-4 {
    width: 75%;
  }

  .md-w-4-5 {
    width: 80%;
  }

  .md-w-5-6 {
    width: 83.333333%;
  }

  .md-flex-row-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md-my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .md-my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .md-my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .md-my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .md-my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .md-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .md-mt-0 {
    margin-top: 0 !important;
  }

  .md-mt-10 {
    margin-top: 10px;
  }

  .md-mt-15 {
    margin-top: 15px;
  }

  .md-mt-30 {
    margin-top: 30px;
  }

  .md-mt-40 {
    margin-top: 40px;
  }

  .md-mt-50 {
    margin-top: 50px;
  }

  .md-mt-70 {
    margin-top: 70px;
  }

  .md-mb-0 {
    margin-bottom: 0 !important;
  }

  .md-mb-10 {
    margin-bottom: 10px !important;
  }

  .md-mb-15 {
    margin-bottom: 15px;
  }

  .md-mb-20 {
    margin-bottom: 20px !important;
  }

  .md-mb-30 {
    margin-bottom: 30px;
  }

  .md-mb-40 {
    margin-bottom: 40px;
  }

  .md-mb-50 {
    margin-bottom: 50px;
  }

  .md-ml-0 {
    margin-left: 0;
  }

  .md-ml-10 {
    margin-left: 10px;
  }

  .md-ml-15 {
    margin-left: 15px;
  }

  .md-ml-20 {
    margin-left: 20px;
  }

  .md-ml-30 {
    margin-left: 30px;
  }

  .md-mr-0 {
    margin-right: 0;
  }

  .md-mr-10 {
    margin-right: 10px;
  }

  .md-mr-15 {
    margin-right: 15px;
  }

  .md-mr-20 {
    margin-right: 20px;
  }

  .md-mr-30 {
    margin-right: 30px;
  }

  .md-p-30 {
    padding: 30px;
  }

  .md-py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .md-py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .md-py-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .md-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .md-px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .md-px-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .md-px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .md-px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .md-px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .md-pl-0 {
    padding-left: 0px;
  }

  .md-pl-10 {
    padding-left: 10px;
  }

  .md-pl-15 {
    padding-left: 15px;
  }

  .md-pl-20 {
    padding-left: 20px;
  }

  .md-pl-30 {
    padding-left: 30px;
  }

  .md-pr-0 {
    padding-right: 0px;
  }

  .md-pr-10 {
    padding-right: 10px;
  }

  .md-pr-15 {
    padding-right: 15px;
  }

  .md-pr-20 {
    padding-right: 20px;
  }

  .md-pr-30 {
    padding-right: 30px;
  }

  .md-pt-0 {
    padding-top: 0;
  }

  .md-pt-10 {
    padding-top: 10px;
  }

  .md-pt-20 {
    padding-top: 20px;
  }

  .md-pt-30 {
    padding-top: 30px;
  }

  .md-pt-40 {
    padding-top: 50px;
  }

  .md-pt-50 {
    padding-top: 50px;
  }

  .md-pb-0 {
    padding-bottom: 0;
  }

  .md-pb-10 {
    padding-bottom: 10px;
  }

  .md-pb-20 {
    padding-bottom: 20px;
  }

  .md-pb-30 {
    padding-bottom: 30px;
  }

  .md-pb-40 {
    padding-bottom: 50px;
  }

  .md-pb-50 {
    padding-bottom: 50px;
  }

  .md-text-xs {
    font-size: 10px;
  }

  .md-text-sm {
    font-size: 12px !important;
  }

  .md-text-base {
    font-size: 16px !important;
  }

  .md-text-lg {
    font-size: 20px !important;
  }

  .md-text-xl {
    font-size: 24px !important;
  }

  .md-text-xl2 {
    font-size: 28px !important;
  }

  .md-text-2xl {
    font-size: 30px !important;
  }
}

@media (min-width: 992px) {
  .lg-min-w-140 {
    min-width: 140px;
  }

  .lg-min-w-100 {
    min-width: 100px;
  }

  .lg-min-w-80 {
    min-width: 80px;
  }

  .lg-min-w-60 {
    min-width: 60px;
  }

  .lg-w-1-6 {
    width: 16.6666%;
  }

  .lg-w-1-5 {
    width: 20%;
  }

  .lg-w-1-4 {
    width: 25%;
  }

  .lg-w-1-3 {
    width: 33.33333%;
  }

  .lg-w-3-8 {
    width: 36.5%;
    padding: 0 15px;
  }

  .lg-w-2-5 {
    width: 40%;
  }

  .lg-w-1-2 {
    width: 50%;
  }

  .lg-w-3-5 {
    width: 60%;
  }

  .lg-w-5-8 {
    width: 62.5%;
    padding: 0 15px;
  }

  .lg-w-2-3 {
    width: 66.666%;
  }

  .lg-w-3-4 {
    width: 75%;
  }

  .lg-w-4-5 {
    width: 80%;
  }

  .lg-w-5-6 {
    width: 83.333333%;
  }

  .lg-flex-row-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg-pl-30 {
    padding-left: 30px;
  }

  .lg-px-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .lg-border-right-grey {
    border-right: 1px solid #9490af;
  }

  .lg-border-left-grey {
    border-left: 1px solid #9490af;
  }

  .lg-br-1 {
    border-right: 1px solid #e0e0e0;
  }

  .lg-my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .lg-my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .lg-my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .lg-my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .lg-my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .lg-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .lg-mt-0 {
    margin-top: 0 !important;
  }

  .lg-mt-10 {
    margin-top: 10px;
  }

  .lg-mt-15 {
    margin-top: 15px;
  }

  .lg-mt-30 {
    margin-top: 30px;
  }

  .lg-mt-40 {
    margin-top: 40px;
  }

  .lg-mt-50 {
    margin-top: 50px;
  }

  .lg-mt-70 {
    margin-top: 70px;
  }

  .lg-mb-0 {
    margin-bottom: 0;
  }

  .lg-mb-10 {
    margin-bottom: 10px;
  }

  .lg-mb-15 {
    margin-bottom: 15px;
  }

  .lg-mb-20 {
    margin-bottom: 20px;
  }

  .lg-mb-30 {
    margin-bottom: 30px;
  }

  .lg-mb-40 {
    margin-bottom: 40px;
  }

  .lg-mb-50 {
    margin-bottom: 50px;
  }

  .lg-mb-60 {
    margin-bottom: 60px;
  }

  .lg-mb-70 {
    margin-bottom: 70px;
  }

  .lg-ml-0 {
    margin-left: 0;
  }

  .lg-ml-10 {
    margin-left: 10px;
  }

  .lg-ml-15 {
    margin-left: 15px;
  }

  .lg-ml-20 {
    margin-left: 20px;
  }

  .lg-ml-30 {
    margin-left: 30px;
  }

  .lg-mr-0 {
    margin-right: 0;
  }

  .lg-mr-10 {
    margin-right: 10px;
  }

  .lg-mr-15 {
    margin-right: 15px;
  }

  .lg-mr-20 {
    margin-right: 20px;
  }

  .lg-mr-30 {
    margin-right: 30px;
  }

  .lg-p-30 {
    padding: 30px;
  }

  .lg-py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .lg-py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .lg-py-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .lg-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lg-px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .lg-px-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .lg-px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .lg-px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .lg-px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .lg-pl-0 {
    padding-left: 0px;
  }

  .lg-pl-10 {
    padding-left: 10px;
  }

  .lg-pl-15 {
    padding-left: 15px;
  }

  .lg-pl-20 {
    padding-left: 20px;
  }

  .lg-pl-30 {
    padding-left: 30px;
  }

  .lg-pr-0 {
    padding-right: 0px;
  }

  .lg-pr-10 {
    padding-right: 10px;
  }

  .lg-pr-15 {
    padding-right: 15px;
  }

  .lg-pr-20 {
    padding-right: 20px;
  }

  .lg-pr-30 {
    padding-right: 30px;
  }

  .lg-pt-0 {
    padding-top: 0;
  }

  .lg-pt-10 {
    padding-top: 10px;
  }

  .lg-pt-20 {
    padding-top: 20px;
  }

  .lg-pt-30 {
    padding-top: 30px;
  }

  .lg-pt-40 {
    padding-top: 50px;
  }

  .lg-pt-50 {
    padding-top: 50px;
  }

  .lg-pb-0 {
    padding-bottom: 0;
  }

  .lg-pb-10 {
    padding-bottom: 10px;
  }

  .lg-pb-20 {
    padding-bottom: 20px;
  }

  .lg-pb-30 {
    padding-bottom: 30px;
  }

  .lg-pb-40 {
    padding-bottom: 50px;
  }

  .lg-pb-50 {
    padding-bottom: 50px;
  }

  .lg-text-xs {
    font-size: 10px !important;
  }

  .lg-text-sm {
    font-size: 12px !important;
  }

  .lg-text-base {
    font-size: 16px !important;
  }

  .lg-text-lg {
    font-size: 20px !important;
  }

  .lg-text-xl {
    font-size: 24px !important;
  }

  .lg-text-xl2 {
    font-size: 28px !important;
  }

  .lg-text-2xl {
    font-size: 30px !important;
  }
}

@media (min-width: 1290px) {
  .xl-min-w-140 {
    min-width: 140px;
  }

  .xl-min-w-100 {
    min-width: 100px;
  }

  .xl-min-w-80 {
    min-width: 80px;
  }

  .xl-min-w-60 {
    min-width: 60px;
  }

  .xl-w-1-6 {
    width: 16.6666%;
  }

  .xl-w-1-5 {
    width: 20%;
  }

  .xl-w-1-4 {
    width: 25%;
  }

  .xl-w-1-3 {
    width: 33.33333%;
  }

  .xl-w-3-8 {
    width: 36.5%;
    padding: 0 15px;
  }

  .xl-w-2-5 {
    width: 40%;
  }

  .xl-w-1-2 {
    width: 50%;
  }

  .xl-w-3-5 {
    width: 60%;
  }

  .xl-w-5-8 {
    width: 62.5%;
    padding: 0 15px;
  }

  .xl-w-2-3 {
    width: 66.666%;
  }

  .xl-w-3-4 {
    width: 75%;
  }

  .xl-w-4-5 {
    width: 80%;
  }

  .xl-w-5-6 {
    width: 83.333333%;
  }

  .xl-flex-row-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xl-my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .xl-my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .xl-my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .xl-my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .xl-my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .xl-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .xl-mt-0 {
    margin-top: 0 !important;
  }

  .xl-mt-10 {
    margin-top: 10px;
  }

  .xl-mt-15 {
    margin-top: 15px;
  }

  .xl-mt-30 {
    margin-top: 30px;
  }

  .xl-mt-40 {
    margin-top: 40px;
  }

  .xl-mt-50 {
    margin-top: 50px;
  }

  .xl-mt-70 {
    margin-top: 70px;
  }

  .xl-mb-0 {
    margin-bottom: 0 !important;
  }

  .xl-mb-10 {
    margin-bottom: 10px !important;
  }

  .xl-mb-15 {
    margin-bottom: 15px;
  }

  .xl-mb-20 {
    margin-bottom: 20px !important;
  }

  .xl-mb-30 {
    margin-bottom: 30px;
  }

  .xl-mb-40 {
    margin-bottom: 40px;
  }

  .xl-mb-50 {
    margin-bottom: 50px;
  }

  .xl-ml-0 {
    margin-left: 0;
  }

  .xl-ml-10 {
    margin-left: 10px;
  }

  .xl-ml-15 {
    margin-left: 15px;
  }

  .xl-ml-20 {
    margin-left: 20px;
  }

  .xl-ml-30 {
    margin-left: 30px;
  }

  .xl-mr-0 {
    margin-right: 0;
  }

  .xl-mr-10 {
    margin-right: 10px;
  }

  .xl-mr-15 {
    margin-right: 15px;
  }

  .xl-mr-20 {
    margin-right: 20px;
  }

  .xl-mr-30 {
    margin-right: 30px;
  }

  .xl-p-30 {
    padding: 30px;
  }

  .xl-py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .xl-py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .xl-py-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .xl-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .xl-px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .xl-px-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .xl-px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .xl-px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .xl-px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .xl-pl-0 {
    padding-left: 0px;
  }

  .xl-pl-10 {
    padding-left: 10px;
  }

  .xl-pl-15 {
    padding-left: 15px;
  }

  .xl-pl-20 {
    padding-left: 20px;
  }

  .xl-pl-30 {
    padding-left: 30px;
  }

  .xl-pr-0 {
    padding-right: 0px;
  }

  .xl-pr-10 {
    padding-right: 10px;
  }

  .xl-pr-15 {
    padding-right: 15px;
  }

  .xl-pr-20 {
    padding-right: 20px;
  }

  .xl-pr-30 {
    padding-right: 30px;
  }

  .xl-pt-0 {
    padding-top: 0;
  }

  .xl-pt-10 {
    padding-top: 10px;
  }

  .xl-pt-20 {
    padding-top: 20px;
  }

  .xl-pt-30 {
    padding-top: 30px;
  }

  .xl-pt-40 {
    padding-top: 50px;
  }

  .xl-pt-50 {
    padding-top: 50px;
  }

  .xl-pb-0 {
    padding-bottom: 0;
  }

  .xl-pb-10 {
    padding-bottom: 10px;
  }

  .xl-pb-20 {
    padding-bottom: 20px;
  }

  .xl-pb-30 {
    padding-bottom: 30px;
  }

  .xl-pb-40 {
    padding-bottom: 50px;
  }

  .xl-pb-50 {
    padding-bottom: 50px;
  }

  .xl-text-xs {
    font-size: 10px;
  }

  .xl-text-sm {
    font-size: 12px !important;
  }

  .xl-text-base {
    font-size: 16px !important;
  }

  .xl-text-lg {
    font-size: 20px !important;
  }

  .xl-text-xl {
    font-size: 24px !important;
  }

  .xl-text-xl2 {
    font-size: 28px !important;
  }

  .xl-text-2xl {
    font-size: 30px !important;
  }
}
