@import 'utilities/colors.scss';

.titleH1 {
  font-size: 62px;
  color: #24476A !important;

  @media only screen and (max-width: 1250px) {
    font-size: 26px;
  }
}

.titleH2 {
  font-size: 48px;
  color: #26a3c5 !important;
  margin: 30px 0;

  @media only screen and (max-width: 1250px) {
    font-size: 26px;
  }
}

.titleH3 {
  font-size: 38px;
  color: #24476A !important;
  margin: 30px 0;

  @media only screen and (max-width: 1250px) {
    font-size: 22px;
  }
}

.customLink {
  text-decoration: none;
  color: #26a3c5;
}

.bodyText {
  color: #24476A !important;
  margin: 30px 0;
}

.nestedList {
  margin-left: 42px;
  color: #24476A !important;
}

.link__block {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 70px;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 35px;
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  .link__item {
    display: flex;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    margin: 0 0.5rem 4rem;
    transition: all 0.3s;
    width: fit-content;

    @media only screen and (max-width: 1200px) {
      margin: 0 0.5rem 2rem;
    }

    &:hover {
      opacity: 0.7;
      transition: all 0.3s;
    }

    .item__text {
      font-size: 1.5rem;
      color: $color_blue;
    }
  }
}

.committee__block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  .committee__name {
    font-weight: 700;
  }
}

.list_item {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;

  .list_dot {
    height: 10px;
    width: 10px;
    background-color: $color_blue;
    border-radius: 50%;
    margin: 0 1rem 0 3rem;
    flex-shrink: 0;

    @media only screen and (max-width: 600px) {
      margin: 0 1rem 0;
    }
  }
}